<!--  -->
<template>
  <div class='user round bj-col-white pad'>
    <h3>个人信息</h3>
    <div>
      <span>头像：</span>
      <div>
        <el-avatar :size="120" :src="info.img"></el-avatar>
        <el-upload
          class="upload-demo"
          action="#"
          :http-request="uploadAction"
          :show-file-list="false"
          v-if="info.img_num != 3"
          :multiple="false">
          <span class="upload">头像上传</span>
        </el-upload>
      </div>
    </div>
    <div>
      <span>姓名：</span>
      <div>{{info.xingming}}</div>
    </div>
    <div>
      <span>联系电话：</span>
      <div>{{info.phone}}</div>
    </div>
    <div>
      <span>身份证号码：</span>
      <div>{{info.card}}</div>
    </div>
    <div>
      <span>执业类型：</span>
      <el-select v-model="info.leixing" @change="onChange" class="picker" placeholder="*所选执业类型需与广东管理系统一致">
        <el-option
          v-for="item in info.leixing_list"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
      <!-- <div>{{info.leixing}}</div>
      <el-dropdown trigger="click" class="cut" @command="onChange">
        <span class="el-dropdown-link">
          <el-link type="primary">切换<i class="el-icon-arrow-down el-icon--right"></i></el-link>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in info.leixing_list" :key="index" :command="item">{{item}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <div class="btn">
      <el-button round :type="update ? 'success' : 'info'" :class="{'self-button--success': update}" style="width: 160px" @click="uploadUserImg">保存</el-button>
    </div>
  </div>
</template>

<script>
import Api from '../api/my'

export default {
  components: {},
  data() {
    return {
      info: {
        img: ''
      },
      update: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getUserInfo()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    onChange (e) {
      Api.upDateType({
        leixing: e
      }).then(res => {
        if (res.data.code == 1) {
          this.$message.success('修改成功')
          this.getUserInfo()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    uploadAction (data) {
      var fileReader = new FileReader();
      fileReader.onload = e => {
        this.info.img = e.target.result;
        this.update = true
      };
      fileReader.readAsDataURL(data.file);
    },
    uploadUserImg () {
      if (!this.update) {
        return
      }
      Api.uploadUserImg({
        file: this.info.img
      }).then(res => {
        if (res.data.code == 1) {
          this.$message.success("保存成功")
        } else {
          this.$message.error("修改失败")
        }
      })
    },
    getUserInfo () {
      Api.getUserInfo().then(res => {
        if (res.status == 200) {
          this.info = res.data
          localStorage.setItem('userInfo', JSON.stringify(this.info))
        } else {
          this.$message.error("用户信息获取失败")
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.user {
  > div {
    margin-top: 30px;
    display: flex;
    align-items: center;
    > span {
      margin-right: 10px;
    }
    /deep/ .el-avatar>img {
      width: 100%;
      height: 100%;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .upload-demo {
        margin-top: 10px;
      }
      .upload {
        color: @default;
        cursor: pointer;
      }
    }
    .cut {
      margin-left: 10px;
      .el-dropdown-link {
        display: flex;
        align-items: center;
      }
    }
  }
  > .btn {
    width: 100%;
    justify-content: center;
  }
}
</style>